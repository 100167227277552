import React from "react"

const Button = ({ disabled = false, children }) => (
  <button
    className="transition duration-200 focus:outline-none rounded-md px-5 py-2 bg-tb-black hover:bg-black hover:scale-110 shadow-md focus:ring ring-gray-500 ring-opacity-50 text-white disabled:opacity-30 select-none"
    disabled={disabled}
  >
    {children}
  </button>
)

export default Button

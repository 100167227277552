import React from "react"
import { Link, graphql } from "gatsby"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import Button from "../components/Button"
import GoogleAdsense from "../components/GoogleAdsense"

export default function Poems({ data }) {
  const { allContentfulPoem } = data
  const { pageInfo } = allContentfulPoem

  return (
    <Layout>
      <Seo
        title={`các bài thơ chọn lọc${
          pageInfo.currentPage > 1 ? ` - trang ${pageInfo.currentPage}` : ``
        }`}
      />

      <div className="relative grid lg:grid-cols-3 gap-8">
        {allContentfulPoem.edges.map(({ node }) => (
          <div
            key={node.id}
            className="border rounded-lg drop-shadow-xl p-5 select-none bg-white"
          >
            <div className="flex flex-col space-y-1">
              <div>
                <Link
                  to={`/poets/${node.poet.slug}`}
                  className="font-medium text-gray-500 text-lg"
                >
                  {node.poet.penName}
                </Link>
              </div>
              <div>
                <Link
                  to={`/poems/${node.slug}`}
                  className="font-alegreya font-medium text-xl"
                >
                  {node.title}
                </Link>
              </div>
            </div>
            <Link
              to={`/poems/${node.slug}`}
              className="block mt-4 flex-grow font-alegreya text-gray-600 whitespace-pre-line"
            >
              {node.childContentfulPoemContentTextNode.childMarkdownRemark.excerpt
                .replace(/[\u2800]/g, "")
                .replace(/\r?\n|\r/g, " / ")}
            </Link>
          </div>
        ))}
      </div>
      <div>
        <div className="text-center text-gray-500">
          trang {pageInfo.currentPage}/{pageInfo.pageCount}
        </div>
        <div className="flex justify-center space-x-4 sm:space-x-6 pt-5">
          <Link
            disabled={!pageInfo.hasPreviousPage}
            style={{
              pointerEvents: pageInfo.hasPreviousPage ? "auto" : "none",
            }}
            to={`/`}
          >
            <Button disabled={!pageInfo.hasPreviousPage}>đầu</Button>
          </Link>
          <Link
            disabled={!pageInfo.hasPreviousPage}
            style={{
              pointerEvents: pageInfo.hasPreviousPage ? "auto" : "none",
            }}
            to={`/${
              pageInfo.currentPage - 1 === 1 ? "" : pageInfo.currentPage - 1
            }`}
          >
            <Button disabled={!pageInfo.hasPreviousPage}>trước</Button>
          </Link>
          <Link
            disabled={!pageInfo.hasNextPage}
            style={{ pointerEvents: pageInfo.hasNextPage ? "auto" : "none" }}
            to={`/${pageInfo.currentPage + 1}`}
          >
            <Button disabled={!pageInfo.hasNextPage}>tiếp</Button>
          </Link>
          <Link
            disabled={!pageInfo.hasNextPage}
            style={{ pointerEvents: pageInfo.hasNextPage ? "auto" : "none" }}
            to={`/${pageInfo.pageCount}`}
          >
            <Button disabled={!pageInfo.hasNextPage}>cuối</Button>
          </Link>
        </div>
      </div>
      <div>
        <GoogleAdsense
          client="ca-pub-1804168523618722"
          slot="3767743010"
          className="google-ads-footer"
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($limit: Int!, $skip: Int!) {
    allContentfulPoem(
      limit: $limit
      skip: $skip
      sort: { fields: publishedDate, order: DESC }
    ) {
      edges {
        node {
          id
          title
          poet {
            penName
            slug
          }
          slug
          publishedDate(formatString: "DD/MM/YYYY")
          childContentfulPoemContentTextNode {
            childMarkdownRemark {
              excerpt(pruneLength: 100, format: PLAIN, truncate: true)
            }
          }
        }
      }
      pageInfo {
        itemCount
        pageCount
        perPage
        totalCount
        hasNextPage
        currentPage
        hasPreviousPage
      }
    }
  }
`

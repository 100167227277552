import React, { useEffect } from "react"

const GoogleAdsense = ({
  className = "",
  style = { display: "block" },
  client = "",
  slot = "",
  format = "auto",
  fullWidthResponsive = "false",
}) => {
  useEffect(() => {
    if (window)
      (window.adsbygoogle = window.adsbygoogle || []).push({
        overlays: { bottom: true }, // anchor ads at bottom (https://support.google.com/adsense/answer/7478225?hl=en)
      })
  }, [])

  return (
    <ins
      className={`${className} adsbygoogle`}
      style={style}
      data-ad-client={client}
      data-ad-slot={slot}
      // data-ad-format={format}
      // data-full-width-responsive={fullWidthResponsive}
    />
  )
}

export default GoogleAdsense
